import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import Layout from "../Page"
import Account from "../../components/component-account"
import { isLoggedIn } from "../../services/auth"

const AccountPage = ({ data: { silverStripeDataObject } }) => {
    const [loading, setLoading] = useState(true)
    const { bannerTitle, bannerSubTitle, bannerImageURL } = silverStripeDataObject.Page;

    useEffect(() => {
        if (!isLoggedIn()) {
            if (typeof window !== 'undefined') {
                window.location.href = "/login/";
            }
        } else {
            setLoading(false)
        }
    }, [])

    return (
        <Layout>
            {loading ? <h2>Loading...</h2> : <Account />}
        </Layout>
    )
}
export default AccountPage
export const pageQuery = graphql`
    query ($link: String!) {
            silverStripeDataObject(link: { eq: $link }) {
                Page{
                    bannerTitle
                    bannerSubTitle
                    bannerImageURL
                }
            }

    }
`;